import '@styles/main.scss';
import jQuery from 'jquery';
import Menu from './components/menu';

class App {
    constructor() {
        new Menu();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
