import jQuery from 'jquery';

export default class Menu {
    constructor() {
        const toggle = document.getElementById('toggle');
        const overlay = document.getElementById('overlay');

        if (toggle === null) return;
        if (overlay === null) return;

        toggle.addEventListener('click', function() {
            toggle.classList.toggle('active');
            overlay.classList.toggle('open');
        });

        const subMenuItems = Array.from(document.getElementsByClassName('menu-item-has-children'));
        const dropdown = '<svg class="sub-menu-dropdown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>';

        if (subMenuItems.length === 0) return;

        subMenuItems.forEach(function(item) {
            item.insertAdjacentHTML('beforeend', dropdown);
        });

        const subMenuIcons = Array.from(document.getElementsByClassName('sub-menu-dropdown'));
        const subMenus = Array.from(document.getElementsByClassName('sub-menu'));

        subMenuIcons.forEach(function(icon, index) {
            icon.addEventListener('click', function() {
                subMenus[index].classList.toggle('open');
                icon.classList.toggle('active');
            });
        });

        const header = document.getElementById('site-header')

        if (!header) {
            return;
        }

        window.addEventListener('scroll', () => {
            const y = window.scrollY;
            if (y > 500 && !header.classList.contains('is-fixed')) {
                header.classList.add('is-fixed');
            } else if (y < 500) {
                header.classList.remove('is-fixed');
            }
        });

    }
}
